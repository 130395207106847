.helpButtonDecrypt {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 30px;
    margin-right: 5%;
    background-color: #800000 !important;
    border: none !important;
    cursor: pointer;
}

.topAlert {
    position: absolute !important;
    text-align: center;
    top: 0 !important;
    left: 0 !important;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 5%;
    border: none !important;
}

.overlayDec {
    font-size: 17px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1;
    background: rgba(0, 0, 0, 0.85);
    padding: 40px;
    margin-top: -254px;
    margin-left: -24px;
    text-align: left;
}
